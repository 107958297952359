import { EcoButton, EcoCard, EcoText } from '@components/shared';
import { MerchantAdmin } from '@ecocart/entities';
import { useOverlay } from '@hooks/useOverlay';
import { syncMerchant } from '@utils/api/shipinsure';
import { useState } from 'react';
import { View } from 'react-native';

interface Props {
  merchantAdmin: MerchantAdmin | undefined;
  refetch: () => void;
}

export default function ShipInsureMerchantCard({ merchantAdmin, refetch }: Props): JSX.Element {
  const [isSyncing, setIsSyncing] = useState(false);
  const { handleError } = useOverlay();

  const handleSyncMerchantPress = async () => {
    if (!merchantAdmin?.shopName) return;
    setIsSyncing(true);

    try {
      await syncMerchant(merchantAdmin?.shopName);
      refetch();
    } catch (error) {
      handleError(error as any);
    } finally {
      setIsSyncing(false);
    }
  };

  return (
    <EcoCard
      title="ShipInsure Merchant"
      topRightComponent={
        <EcoButton isDisabled={isSyncing} size="sm" onPress={handleSyncMerchantPress} isLoading={isSyncing}>
          Sync Merchant
        </EcoButton>
      }
    >
      <View className="flex-row flex-start">
        <EcoText className="w-[120px]" color="subdued">
          Merchant UUID:
        </EcoText>
        <EcoText fontWeight="semibold" color="subdued">
          {merchantAdmin?.siMerchantUuid || 'Unknown'}
        </EcoText>
      </View>
    </EcoCard>
  );
}
